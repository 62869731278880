import React from 'react'
import { Link } from 'gatsby'
import { Emoji } from 'emoji-mart';

let beer = <Emoji size={1.5} emoji=':beer:' sheetSize={64} set='apple'/>;
let coffee = <Emoji size={1.5} emoji=':coffee:' sheetSize={64} set='apple'/>;
let snek = <Emoji size={1.5} emoji=':snake:' sheetSize={64} set='apple'/>;

let bitcoin = require('../images/bitcoin.png')
let ethereum = require('../images/ethereum.png')

class Footer extends React.Component {
  render () {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <p className="copyright text-muted">Copyright © NLP <span style={{color:'#FFB900'}}>Champs</span> 2017 - Inconceivable Natural Language Processing Techniques | Buy us some {beer}'s or {coffee}'s: <a href="paypal.me/chrisfrewin">PayPal</a> <img src={bitcoin} alt="Bitcoin address" height="15rem"/>1D6aFgTLZrV4QAJ5ZhjDxV8Rj78LLjKvcm | <img src={ethereum} alt="Ethereum address" height="15rem"/> 0x1bc3850619803C48b79481A3Aee167141be20432 | <Link to="/legal">Legal</Link> <Link to="/snek">{snek}</Link> </p>
            </div>
          </div>
        </div>
        <style dangerouslySetInnerHTML={{__html: ".acfp-button img{width: 27px !important;margin-bottom: 3px !important;box-shadow: none !important;border: none !important;vertical-align: middle !important;}.acfp-button{line-height: 36px !important;height:37px !important;text-decoration: none !important;display:inline-block !important;color:#000000 !important;background-color:red !important;border-radius: 3px !important;border: 1px solid transparent !important;padding: 1px 9px !important;font-size: 23px !important;letter-spacing: 0.6px !important;box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;-webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;margin: 0 auto !important;font-family:'Pacifico', cursive !important;-webkit-box-sizing: border-box !important;box-sizing: border-box !important;-o-transition: 0.3s all linear !important;-webkit-transition: 0.3s all linear !important;-moz-transition: 0.3s all linear !important;-ms-transition: 0.3s all linear !important;transition: 0.3s all linear !important;}.acfp-button:hover, .acfp-button:active, .acfp-button:focus {-webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;text-decoration: none !important;box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;opacity: 0.85 !important;color:#000000 !important;}" }} /><link href="https://fonts.googleapis.com/css?family=Pacifico" rel="stylesheet" />
      </footer>
    )
  }
}

export default Footer;
