import React from 'react'
import {Link} from 'gatsby'

class Nav extends React.Component {
  constructor() {
    super();
    this.state = {
      ariaExpanded: true,
      navBarClass: "collapse navbar-collapse"
    }
    this.toggleAriaExpanded = this.toggleAriaExpanded.bind(this);
  }
  toggleAriaExpanded() {
    if (this.state.navBarClass === "collapse navbar-collapse") {
      this.setState({navBarClass: ""});
    } else {
      this.setState({navBarClass: "collapse navbar-collapse"});
    }
    this.setState({ariaExpanded: !this.state.ariaExpanded});
  }
  render () {
    return (
      <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
        <div className="container">
          <Link className="navbar-brand" to="/">NLP <span style={{color:'#FFB900'}}>Champs</span></Link>
          <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded={this.state.ariaExpanded} onClick={this.toggleAriaExpanded} aria-label="Toggle navigation">
            Menu
            <i className="fa fa-bars" />
          </button>
          <div className={this.state.navBarClass} id="navbarResponsive" aria-expanded={this.state.ariaExpanded}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link onClick={this.toggleAriaExpanded} className="nav-link" to="/">Posts</Link>
              </li>
              <li className="nav-item">
                <Link onClick={this.toggleAriaExpanded} className="nav-link" to="/what-is-nlp">What is NLP?</Link>
              </li>
              <li className="nav-item">
                <Link onClick={this.toggleAriaExpanded} className="nav-link" to="/getting-started">Getting Started</Link>
              </li>
              <li className="nav-item">
                <Link onClick={this.toggleAriaExpanded} className="nav-link" to="/resources">Resources</Link>
              </li>
              <li className="nav-item">
                <Link onClick={this.toggleAriaExpanded} className="nav-link" to="/about-nlp-champs">About NLP <span style={{color:'#FFB900'}}>Champs</span></Link>
              </li>
              <li className="nav-item">
                <Link onClick={this.toggleAriaExpanded} className="nav-link" to="/meet-the-champs-or-become-a-champ">Meet the Champs or Become a Champ</Link>
              </li>
              <li className="nav-item">
                <Link onClick={this.toggleAriaExpanded} className="nav-link" to="/the-nlp-times-newsletter">The NLP Times Newsletter</Link>
              </li>
              <li className="nav-item">
                <Link onClick={this.toggleAriaExpanded} className="nav-link" to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Nav;
