import React from 'react'
import '../styles/App_backup.css' // note that this is the best working css ---> we should reverse migrate it to scss at some point
// import '../styles/App.css' // note that this is the best working css ---> we should reverse migrate it to scss at some point

import Nav from '../components/Nav';
import Footer from '../components/Footer';

require('prismjs/themes/prism-okaidia.css');

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div>
          {/* Nav */}
          <Nav/>
          {children}
          <hr/>
          {/* Footer */}
          <Footer/>
      </div>
    )
  }
}

export default Layout
